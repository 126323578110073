import React from "react";
import "./styles.css";
import "../dapp.css";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import imgBalance from "../../../assets/images/logo/grey.png";
import imgMatic from "../../../assets/images/matic.png";
import imgUSDT from "../../../assets/images/usdt.svg";
import imgETHPOOL from "../../../assets/images/ETHPOOL.svg";
import imgUSDC from "../../../assets/images/USDC.svg";
import imgDAI from "../../../assets/images/DAI.svg";

const DappEarn = ({
  isConnected,
  getSigner,
  provider,
  signerAddress,
  chains,
  setChainSelected,
  chainSelected,
}) => {
  var displayAddress = `${signerAddress?.substring(0, 10)}...`;
  return (
    <>
      <Header
        isConnected={isConnected}
        displayAddress={displayAddress}
        getSigner={getSigner}
        provider={provider}
        chains={chains}
        chainSelected={chainSelected}
        setChainSelected={setChainSelected}
      />
      <section className="dapp-section">
        <div className="pp-container">
          <div className="pt-4 text-2xl font-semibold text-primary md:pt-0 bl-0101">
            Balance Earn
          </div>

          <div>
            <div className="flex flex-col flex-wrap md:flex-row md:items-center">
              <div className="transition rounded-lg flex border border-water-500 bg-transparent cursor-text focus-within:ring-water-300 focus-within:border-sky w-full md:w-[300px] space-between">
                <input
                  className="w-full md:w-[300px] bg-transparent text-white placeholder:text-water-500 outline-none border-none focus:border-none focus:placeholder:text-water-300 focus:ring-0 block w-full px-3 py-2 text-base transition rounded-lg h-9 bl-0102"
                  id="pp-searchinput-4799"
                  type="text"
                  placeholder="Search"
                />
                <label
                  for="pp-searchinput-4799"
                  className="flex items-center pr-3 transition cursor-text text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </label>
              </div>
              <div className="flex-grow hidden md:block"></div>
              <div className="flex justify-end order-12 w-full pt-4 pb-8 md:pt-4 md:pb-4 lg:pt-0 lg:pb-0 lg:pr-4 lg:order-none lg:w-auto">
                <div
                  className="flex items-center gap-x-1.5"
                  data-headlessui-state=""
                >
                  <label
                    id="headlessui-listbox-label-89"
                    data-headlessui-state=""
                    className=""
                  >
                    Sort by:
                  </label>
                  <div className="relative">
                    <button
                      id="headlessui-listbox-button-90"
                      type="button"
                      aria-haspopup="listbox"
                      aria-expanded="false"
                      data-headlessui-state=""
                      className="relative py-1 pl-0.5 pr-6 transition-colors text-left text-primary border-2 border-transparent focus:outline-none group focus:border-b-grass hover:border-b-grass border-b-white"
                      aria-labelledby="headlessui-listbox-label-89 headlessui-listbox-button-90"
                    >
                      <span className="transition group-hover:text-grass group-focus:text-grass bl-0111">
                        Starred
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
                        <svg
                          width="14"
                          height="8"
                          viewBox="0 0 14 8"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3.5 w-3.5 relative top-px transition group-focus:text-grass group-hover:text-grass"
                          aria-hidden="true"
                        >
                          <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex mt-6 md:mt-0">
                <div
                  className="flex rounded-full bg-water-700 pp-tab-items bl-0103"
                  variant="pill"
                  data-test="is-expired-slider"
                >
                  <button
                    type="button"
                    className="pp-btn relative flex-1 !border-none px-4 py-1 px-2.5 py-1.5 text-base rounded-2xl text-water-300 enabled:hover:text-water-200 focus:text-water-200 transition !bg-gradient-blue-white-light !text-water-700 !font-medium"
                  >
                    <span>Active</span>
                  </button>
                  <button
                    type="button"
                    className="pp-btn relative flex-1 !border-none px-4 py-1 px-2.5 py-1.5 text-base rounded-2xl text-water-300 enabled:hover:text-water-200 focus:text-water-200 transition"
                  >
                    <span>Inactive</span>
                  </button>
                </div>
                <div className="flex-grow block md:hidden"></div>
              </div>
              <div
                role="tablist"
                aria-orientation="horizontal"
                className="flex pp-tab-items hidden ml-4 children:p-1 md:flex hidden ml-4 children:p-1 md:flex"
                modelvalue="1"
                variant="text"
                data-test="layout-selector"
              ></div>
            </div>
            <div className="mt-4">
              <div>
                <span
                  className="bl-0104"
                  id="headlessui-tabs-panel-96"
                  role="tabpanel"
                  tabindex="-1"
                  aria-labelledby="headlessui-tabs-tab-92"
                ></span>
                <div
                  id="headlessui-tabs-panel-97"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-93"
                  tabindex="0"
                  data-headlessui-state="selected"
                >
                  <div className="pt-6 lg:pt-10">
                    <div>
                      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-14">
                        <a
                          data-v-ac9cfe0a=""
                          href="#"
                          className="!bg-opacity-10 pp-card bg-water-200 bg-opacity-[0.07] text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-water-200 hover:neon-water-200 focus:border-water-200 focus:neon-water-200 !bg-opacity-10"
                        >
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-title relative"
                          >
                            <div
                              data-v-ac9cfe0a=""
                              className="bl-0112 rounded-t-xl absolute inset-0 z-[-1] isolate h-24 overflow-hidden w-full title-strip"
                            ></div>
                            <div
                              data-v-ac9cfe0a=""
                              data-test="0xd16cb4138a0f09885defdbc1fe4a65a8f2fb3950-symbol"
                              className="flex items-center justify-between"
                            >
                              <div data-v-ac9cfe0a="">
                                <div data-v-ac9cfe0a="" className="flex">
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="text-xl font-bold text-white"
                                  >
                                    BALANCE Pool
                                  </div>
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="ml-2 relative top-0.5"
                                  >
                                    <button data-v-ac9cfe0a="" type="button">
                                      <svg
                                        className="text-water-600 hover:text-[#EFB54B] transition"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                                <div
                                  data-v-ac9cfe0a=""
                                  className="mt-0.5 text-base text-water-300"
                                >
                                  Balance Network
                                </div>
                              </div>
                              <img
                                data-v-ac9cfe0a=""
                                src={imgBalance}
                                alt="Balance"
                                style={{
                                  borderRadius: "50%",
                                  background: "#ffffff",
                                }}
                                className="bl-0113 inline-block h-16 w-16 pp-market-glow absolute -mt-20 right-6"
                              />
                            </div>
                          </div>
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-body space-y-4 font-bold"
                          >
                            <div data-v-ac9cfe0a="">
                              <div
                                data-v-ac9cfe0a=""
                                className="flex items-center gap-1"
                              >
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-xl text-grass"
                                  data-test="max-apy"
                                >
                                  <span
                                    title="11.039901623605303"
                                    className="whitespace-nowrap"
                                  >
                                    120.16
                                  </span>
                                  %
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-base font-normal text-white"
                                >
                                  {" "}
                                  p.a{" "}
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  data-v-tippy=""
                                  aria-expanded="false"
                                >
                                  <svg
                                    data-v-ac9cfe0a=""
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-emerald"
                                  >
                                    <g clip-path="url(#clip0_12081_62215)">
                                      <path
                                        d="M9.8448 0.0595203C9.93044 0.109166 9.99671 0.186387 10.0328 0.278566C10.0689 0.370745 10.0726 0.472434 10.0434 0.56702L8.46755 5.68752H11.3752C11.4606 5.68749 11.5442 5.71247 11.6156 5.7594C11.687 5.80633 11.7431 5.87314 11.777 5.95159C11.8109 6.03004 11.821 6.11669 11.8062 6.20084C11.7914 6.285 11.7523 6.36297 11.6937 6.42515L4.69367 13.8626C4.62594 13.9347 4.53581 13.9816 4.43798 13.9959C4.34014 14.0101 4.24036 13.9908 4.15491 13.9411C4.06946 13.8913 4.00337 13.8141 3.96742 13.722C3.93146 13.6299 3.92776 13.5284 3.95692 13.4339L5.5328 8.31252H2.62517C2.53973 8.31255 2.45614 8.28757 2.38473 8.24064C2.31332 8.19371 2.25722 8.1269 2.22334 8.04845C2.18947 7.97 2.17931 7.88335 2.19412 7.7992C2.20893 7.71504 2.24806 7.63707 2.30667 7.57489L9.30667 0.137395C9.37432 0.0654695 9.46431 0.0185428 9.56201 0.00424529C9.65971 -0.0100523 9.75938 0.0091202 9.8448 0.0586453V0.0595203Z"
                                        fill="currentColor"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_12081_62215">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        ></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div data-v-52d5a9f9="" className="mt-4">
                              <div
                                data-v-52d5a9f9=""
                                className="bg-water-200/10 text-water-300/75 italic py-1 px-2 rounded-[5px]"
                                style={{ fontWeight: "400" }}
                              >
                                {" "}
                                No position{" "}
                              </div>
                            </div>
                            <div data-v-ac9cfe0a="" className="flex">
                              <div className="">
                                <span data-v-ac9cfe0a="" data-v-tippy="">
                                  {" "}
                                  Maturity{" "}
                                </span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div
                                  data-v-ac9cfe0a=""
                                  className="flex flex-wrap justify-end gap-x-1"
                                >
                                  <span
                                    data-v-ac9cfe0a=""
                                    data-test="expiry"
                                    className="font-normal text-primary"
                                  >
                                    27 Mar 2025
                                  </span>
                                  <span
                                    data-v-ac9cfe0a=""
                                    title="27/03/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="font-normal"
                                  >
                                    <span className=""></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          data-v-ac9cfe0a=""
                          href="#"
                          className="!bg-opacity-10 pp-card bg-water-200 bg-opacity-[0.07] text-water-300 bg-size-105 bg-center border border-transparent focus:outline-none hover:neon-sm focus:neon-sm hover:border-water-200 hover:neon-water-200 focus:border-water-200 focus:neon-water-200"
                        >
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-title relative"
                          >
                            <div
                              data-v-ac9cfe0a=""
                              className="rounded-t-xl absolute inset-0 z-[-1] isolate h-24 overflow-hidden w-full title-strip bl-0114"
                            ></div>
                            <div
                              data-v-ac9cfe0a=""
                              data-test="0x30e0dc9a1d33eac83211a1113de238b3ce826950-symbol"
                              className="flex items-center justify-between"
                            >
                              <div data-v-ac9cfe0a="">
                                <div data-v-ac9cfe0a="" className="flex">
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="text-xl font-bold text-white"
                                  >
                                    USDT Pool
                                  </div>
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="ml-2 relative top-0.5"
                                  >
                                    <button data-v-ac9cfe0a="" type="button">
                                      <svg
                                        className="text-water-600 hover:text-[#EFB54B] transition"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                                <div
                                  data-v-ac9cfe0a=""
                                  className="mt-0.5 text-base text-water-300"
                                >
                                  Stargate
                                </div>
                              </div>
                              <img
                                data-v-ac9cfe0a=""
                                src={imgUSDT}
                                alt="USDT Pool"
                                className="bl-0115 inline-block h-16 w-16 pp-market-glow absolute -mt-20 right-6"
                              />
                            </div>
                          </div>
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-body space-y-4 font-bold"
                          >
                            <div data-v-ac9cfe0a="">
                              <div
                                data-v-ac9cfe0a=""
                                className="flex items-center gap-1"
                              >
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-xl text-grass"
                                  data-test="max-apy"
                                >
                                  <span
                                    title="11.96079301976076"
                                    className="whitespace-nowrap"
                                  >
                                    3.10
                                  </span>
                                  %
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-base font-normal text-white"
                                >
                                  {" "}
                                  p.a{" "}
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  data-v-tippy=""
                                  aria-expanded="false"
                                >
                                  <svg
                                    data-v-ac9cfe0a=""
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-emerald"
                                  >
                                    <g clip-path="url(#clip0_12081_62215)">
                                      <path
                                        d="M9.8448 0.0595203C9.93044 0.109166 9.99671 0.186387 10.0328 0.278566C10.0689 0.370745 10.0726 0.472434 10.0434 0.56702L8.46755 5.68752H11.3752C11.4606 5.68749 11.5442 5.71247 11.6156 5.7594C11.687 5.80633 11.7431 5.87314 11.777 5.95159C11.8109 6.03004 11.821 6.11669 11.8062 6.20084C11.7914 6.285 11.7523 6.36297 11.6937 6.42515L4.69367 13.8626C4.62594 13.9347 4.53581 13.9816 4.43798 13.9959C4.34014 14.0101 4.24036 13.9908 4.15491 13.9411C4.06946 13.8913 4.00337 13.8141 3.96742 13.722C3.93146 13.6299 3.92776 13.5284 3.95692 13.4339L5.5328 8.31252H2.62517C2.53973 8.31255 2.45614 8.28757 2.38473 8.24064C2.31332 8.19371 2.25722 8.1269 2.22334 8.04845C2.18947 7.97 2.17931 7.88335 2.19412 7.7992C2.20893 7.71504 2.24806 7.63707 2.30667 7.57489L9.30667 0.137395C9.37432 0.0654695 9.46431 0.0185428 9.56201 0.00424529C9.65971 -0.0100523 9.75938 0.0091202 9.8448 0.0586453V0.0595203Z"
                                        fill="currentColor"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_12081_62215">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        ></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div data-v-52d5a9f9="" className="mt-4">
                              <div
                                data-v-52d5a9f9=""
                                className="bg-water-200/10 text-water-300/75 italic py-1 px-2 rounded-[5px]"
                                style={{ fontWeight: "400" }}
                              >
                                {" "}
                                No position{" "}
                              </div>
                            </div>
                            <div data-v-ac9cfe0a="" className="flex">
                              <div className="">
                                <span data-v-ac9cfe0a="" data-v-tippy="">
                                  {" "}
                                  Maturity{" "}
                                </span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div
                                  data-v-ac9cfe0a=""
                                  className="flex flex-wrap justify-end gap-x-1"
                                >
                                  <span
                                    data-v-ac9cfe0a=""
                                    data-test="expiry"
                                    className="font-normal text-primary"
                                  >
                                    26 Jun 2025
                                  </span>
                                  <span
                                    data-v-ac9cfe0a=""
                                    title="26/06/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="font-normal"
                                  >
                                    <span className=""></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          data-v-ac9cfe0a=""
                          href="#"
                          className="!bg-opacity-10 pp-card bg-water-200 bg-opacity-[0.07] text-water-300 bg-size-105 bg-center border border-transparent focus:outline-none hover:neon-sm focus:neon-sm hover:border-water-200 hover:neon-water-200 focus:border-water-200 focus:neon-water-200"
                        >
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-title relative"
                          >
                            <div
                              data-v-ac9cfe0a=""
                              className="rounded-t-xl absolute inset-0 isolate h-24 overflow-hidden w-full title-strip bl-0116"
                            ></div>
                            <div
                              data-v-ac9cfe0a=""
                              data-test="0xd0354d4e7bcf345fb117cabe41acadb724eccca2-symbol"
                              className="flex items-center justify-between"
                            >
                              <div data-v-ac9cfe0a="">
                                <div data-v-ac9cfe0a="" className="flex">
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="text-xl font-bold text-white"
                                  >
                                    ETH Pool
                                  </div>
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="ml-2 relative top-0.5"
                                  >
                                    <button data-v-ac9cfe0a="" type="button">
                                      <svg
                                        className="text-water-600 hover:text-[#EFB54B] transition"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                                <div
                                  data-v-ac9cfe0a=""
                                  className="mt-0.5 text-base text-water-300"
                                >
                                  Lido
                                </div>
                              </div>
                              <img
                                data-v-ac9cfe0a=""
                                src={imgETHPOOL}
                                alt="ETH Pool"
                                className="bl-0117 inline-block h-16 w-16 pp-market-glow absolute -mt-20 right-6"
                              />
                            </div>
                          </div>
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-body space-y-4 font-bold"
                          >
                            <div data-v-ac9cfe0a="">
                              <div
                                data-v-ac9cfe0a=""
                                className="flex items-center gap-1"
                              >
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-xl text-grass"
                                  data-test="max-apy"
                                >
                                  <span
                                    title="5.87175774637789"
                                    className="whitespace-nowrap"
                                  >
                                    4.48
                                  </span>
                                  %
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-base font-normal text-white"
                                >
                                  {" "}
                                  p.a{" "}
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  data-v-tippy=""
                                  aria-expanded="false"
                                >
                                  <svg
                                    data-v-ac9cfe0a=""
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-emerald"
                                  >
                                    <g clip-path="url(#clip0_12081_62215)">
                                      <path
                                        d="M9.8448 0.0595203C9.93044 0.109166 9.99671 0.186387 10.0328 0.278566C10.0689 0.370745 10.0726 0.472434 10.0434 0.56702L8.46755 5.68752H11.3752C11.4606 5.68749 11.5442 5.71247 11.6156 5.7594C11.687 5.80633 11.7431 5.87314 11.777 5.95159C11.8109 6.03004 11.821 6.11669 11.8062 6.20084C11.7914 6.285 11.7523 6.36297 11.6937 6.42515L4.69367 13.8626C4.62594 13.9347 4.53581 13.9816 4.43798 13.9959C4.34014 14.0101 4.24036 13.9908 4.15491 13.9411C4.06946 13.8913 4.00337 13.8141 3.96742 13.722C3.93146 13.6299 3.92776 13.5284 3.95692 13.4339L5.5328 8.31252H2.62517C2.53973 8.31255 2.45614 8.28757 2.38473 8.24064C2.31332 8.19371 2.25722 8.1269 2.22334 8.04845C2.18947 7.97 2.17931 7.88335 2.19412 7.7992C2.20893 7.71504 2.24806 7.63707 2.30667 7.57489L9.30667 0.137395C9.37432 0.0654695 9.46431 0.0185428 9.56201 0.00424529C9.65971 -0.0100523 9.75938 0.0091202 9.8448 0.0586453V0.0595203Z"
                                        fill="currentColor"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_12081_62215">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        ></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div data-v-52d5a9f9="" className="mt-4">
                              <div
                                data-v-52d5a9f9=""
                                className="bg-water-200/10 text-water-300/75 italic py-1 px-2 rounded-[5px]"
                                style={{ fontWeight: "400" }}
                              >
                                {" "}
                                No position{" "}
                              </div>
                            </div>
                            <div data-v-ac9cfe0a="" className="flex">
                              <div className="">
                                <span data-v-ac9cfe0a="" data-v-tippy="">
                                  {" "}
                                  Maturity{" "}
                                </span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div
                                  data-v-ac9cfe0a=""
                                  className="flex flex-wrap justify-end gap-x-1"
                                >
                                  <span
                                    data-v-ac9cfe0a=""
                                    data-test="expiry"
                                    className="font-normal text-primary"
                                  >
                                    25 Dec 2025
                                  </span>
                                  <span
                                    data-v-ac9cfe0a=""
                                    title="25/12/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="font-normal"
                                  >
                                    <span className=""></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          data-v-ac9cfe0a=""
                          href="#"
                          className="!bg-opacity-10 pp-card bg-water-200 bg-opacity-[0.07] text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-water-200 hover:neon-water-200 focus:border-water-200 focus:neon-water-200 !bg-opacity-10"
                        >
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-title relative"
                          >
                            <div
                              data-v-ac9cfe0a=""
                              className="rounded-t-xl absolute inset-0 z-[-1] isolate h-24 overflow-hidden w-full title-strip bl-0109"
                            ></div>
                            <div
                              data-v-ac9cfe0a=""
                              data-test="0x66317dec4a3d8d1e47b85f704e5df675a68bb7c9-symbol"
                              className="flex items-center justify-between"
                            >
                              <div data-v-ac9cfe0a="">
                                <div data-v-ac9cfe0a="" className="flex">
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="text-xl font-bold text-white"
                                  >
                                    MATIC Pool
                                  </div>
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="ml-2 relative top-0.5"
                                  >
                                    <button data-v-ac9cfe0a="" type="button">
                                      <svg
                                        className="text-water-600 hover:text-[#EFB54B] transition"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                                <div
                                  data-v-ac9cfe0a=""
                                  className="mt-0.5 text-base text-water-300"
                                >
                                  Thena
                                </div>
                              </div>
                              <img
                                data-v-ac9cfe0a=""
                                src={imgMatic}
                                alt="MATIC Pool"
                                className="inline-block h-16 w-16 pp-market-glow absolute -mt-20 right-6 bl-0110"
                              />
                            </div>
                          </div>
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-body space-y-4 font-bold"
                          >
                            <div data-v-ac9cfe0a="">
                              <div
                                data-v-ac9cfe0a=""
                                className="flex items-center gap-1"
                              >
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-xl text-grass"
                                  data-test="max-apy"
                                >
                                  <span
                                    title="8.430362950178318"
                                    className="whitespace-nowrap"
                                  >
                                    5.22
                                  </span>
                                  %
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-base font-normal text-white"
                                >
                                  p.a{" "}
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  data-v-tippy=""
                                  aria-expanded="false"
                                >
                                  <svg
                                    data-v-ac9cfe0a=""
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-emerald"
                                  >
                                    <g clip-path="url(#clip0_12081_62215)">
                                      <path
                                        d="M9.8448 0.0595203C9.93044 0.109166 9.99671 0.186387 10.0328 0.278566C10.0689 0.370745 10.0726 0.472434 10.0434 0.56702L8.46755 5.68752H11.3752C11.4606 5.68749 11.5442 5.71247 11.6156 5.7594C11.687 5.80633 11.7431 5.87314 11.777 5.95159C11.8109 6.03004 11.821 6.11669 11.8062 6.20084C11.7914 6.285 11.7523 6.36297 11.6937 6.42515L4.69367 13.8626C4.62594 13.9347 4.53581 13.9816 4.43798 13.9959C4.34014 14.0101 4.24036 13.9908 4.15491 13.9411C4.06946 13.8913 4.00337 13.8141 3.96742 13.722C3.93146 13.6299 3.92776 13.5284 3.95692 13.4339L5.5328 8.31252H2.62517C2.53973 8.31255 2.45614 8.28757 2.38473 8.24064C2.31332 8.19371 2.25722 8.1269 2.22334 8.04845C2.18947 7.97 2.17931 7.88335 2.19412 7.7992C2.20893 7.71504 2.24806 7.63707 2.30667 7.57489L9.30667 0.137395C9.37432 0.0654695 9.46431 0.0185428 9.56201 0.00424529C9.65971 -0.0100523 9.75938 0.0091202 9.8448 0.0586453V0.0595203Z"
                                        fill="currentColor"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_12081_62215">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        ></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div data-v-52d5a9f9="" className="mt-4">
                              <div
                                data-v-52d5a9f9=""
                                className="bg-water-200/10 text-water-300/75 italic py-1 px-2 rounded-[5px]"
                                style={{ fontWeight: "400" }}
                              >
                                {" "}
                                No position{" "}
                              </div>
                            </div>
                            <div data-v-ac9cfe0a="" className="flex">
                              <div className="">
                                <span data-v-ac9cfe0a="" data-v-tippy="">
                                  Maturity
                                </span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div
                                  data-v-ac9cfe0a=""
                                  className="flex flex-wrap justify-end gap-x-1"
                                >
                                  <span
                                    data-v-ac9cfe0a=""
                                    data-test="expiry"
                                    className="font-normal text-primary"
                                  >
                                    26 Jun 2025
                                  </span>
                                  <span
                                    data-v-ac9cfe0a=""
                                    title="26/06/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="font-normal"
                                  >
                                    <span className=""></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          data-v-ac9cfe0a=""
                          href="#"
                          className="!bg-opacity-10 pp-card bg-water-200 bg-opacity-[0.07] text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-water-200 hover:neon-water-200 focus:border-water-200 focus:neon-water-200 !bg-opacity-10"
                        >
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-title relative"
                          >
                            <div
                              data-v-ac9cfe0a=""
                              className="rounded-t-xl absolute inset-0 z-[-1] isolate h-24 overflow-hidden w-full title-strip bl-0118"
                            ></div>
                            <div
                              data-v-ac9cfe0a=""
                              data-test="0x66317dec4a3d8d1e47b85f704e5df675a68bb7c9-symbol"
                              className="flex items-center justify-between"
                            >
                              <div data-v-ac9cfe0a="">
                                <div data-v-ac9cfe0a="" className="flex">
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="text-xl font-bold text-white"
                                  >
                                    USDC Pool
                                  </div>
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="ml-2 relative top-0.5"
                                  >
                                    <button data-v-ac9cfe0a="" type="button">
                                      <svg
                                        className="text-water-600 hover:text-[#EFB54B] transition"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                                <div
                                  data-v-ac9cfe0a=""
                                  className="mt-0.5 text-base text-water-300"
                                >
                                  Flux
                                </div>
                              </div>
                              <img
                                data-v-ac9cfe0a=""
                                src={imgUSDC}
                                alt="USDC Pool"
                                className="bl-0119 inline-block h-16 w-16 pp-market-glow absolute -mt-20 right-6"
                              />
                            </div>
                          </div>
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-body space-y-4 font-bold"
                          >
                            <div data-v-ac9cfe0a="">
                              <div
                                data-v-ac9cfe0a=""
                                className="flex items-center gap-1"
                              >
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-xl text-grass"
                                  data-test="max-apy"
                                >
                                  <span
                                    title="30.541829540268456"
                                    className="whitespace-nowrap"
                                  >
                                    2.98
                                  </span>
                                  %
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-base font-normal text-white"
                                >
                                  {" "}
                                  p.a{" "}
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  data-v-tippy=""
                                  aria-expanded="false"
                                >
                                  <svg
                                    data-v-ac9cfe0a=""
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-emerald"
                                  >
                                    <g clip-path="url(#clip0_12081_62215)">
                                      <path
                                        d="M9.8448 0.0595203C9.93044 0.109166 9.99671 0.186387 10.0328 0.278566C10.0689 0.370745 10.0726 0.472434 10.0434 0.56702L8.46755 5.68752H11.3752C11.4606 5.68749 11.5442 5.71247 11.6156 5.7594C11.687 5.80633 11.7431 5.87314 11.777 5.95159C11.8109 6.03004 11.821 6.11669 11.8062 6.20084C11.7914 6.285 11.7523 6.36297 11.6937 6.42515L4.69367 13.8626C4.62594 13.9347 4.53581 13.9816 4.43798 13.9959C4.34014 14.0101 4.24036 13.9908 4.15491 13.9411C4.06946 13.8913 4.00337 13.8141 3.96742 13.722C3.93146 13.6299 3.92776 13.5284 3.95692 13.4339L5.5328 8.31252H2.62517C2.53973 8.31255 2.45614 8.28757 2.38473 8.24064C2.31332 8.19371 2.25722 8.1269 2.22334 8.04845C2.18947 7.97 2.17931 7.88335 2.19412 7.7992C2.20893 7.71504 2.24806 7.63707 2.30667 7.57489L9.30667 0.137395C9.37432 0.0654695 9.46431 0.0185428 9.56201 0.00424529C9.65971 -0.0100523 9.75938 0.0091202 9.8448 0.0586453V0.0595203Z"
                                        fill="currentColor"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_12081_62215">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        ></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div data-v-52d5a9f9="" className="mt-4">
                              <div
                                data-v-52d5a9f9=""
                                className="bg-water-200/10 text-water-300/75 italic py-1 px-2 rounded-[5px]"
                                style={{ fontWeight: "400" }}
                              >
                                {" "}
                                No position{" "}
                              </div>
                            </div>
                            <div data-v-ac9cfe0a="" className="flex">
                              <div className="">
                                <span data-v-ac9cfe0a="" data-v-tippy="">
                                  {" "}
                                  Maturity{" "}
                                </span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div
                                  data-v-ac9cfe0a=""
                                  className="flex flex-wrap justify-end gap-x-1"
                                >
                                  <span
                                    data-v-ac9cfe0a=""
                                    data-test="expiry"
                                    className="font-normal text-primary"
                                  >
                                    25 Dec 2025
                                  </span>
                                  <span
                                    data-v-ac9cfe0a=""
                                    title="25/12/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="font-normal"
                                  >
                                    <span className=""></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          data-v-ac9cfe0a=""
                          href="#"
                          className="!bg-opacity-10 pp-card bg-water-200 bg-opacity-[0.07] text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-water-200 hover:neon-water-200 focus:border-water-200 focus:neon-water-200 !bg-opacity-10"
                        >
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-title relative"
                          >
                            <div
                              data-v-ac9cfe0a=""
                              className="rounded-t-xl absolute inset-0 z-[-1] isolate h-24 overflow-hidden w-full title-strip bl-0120"
                            ></div>
                            <div
                              data-v-ac9cfe0a=""
                              data-test="0x66317dec4a3d8d1e47b85f704e5df675a68bb7c9-symbol"
                              className="flex items-center justify-between"
                            >
                              <div data-v-ac9cfe0a="">
                                <div data-v-ac9cfe0a="" className="flex">
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="text-xl font-bold text-white"
                                  >
                                    DAI Pool
                                  </div>
                                  <div
                                    data-v-ac9cfe0a=""
                                    className="ml-2 relative top-0.5"
                                  >
                                    <button data-v-ac9cfe0a="" type="button">
                                      <svg
                                        className="text-water-600 hover:text-[#EFB54B] transition"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                                <div
                                  data-v-ac9cfe0a=""
                                  className="mt-0.5 text-base text-water-300"
                                >
                                  Spark{" "}
                                </div>
                              </div>
                              <img
                                data-v-ac9cfe0a=""
                                src={imgDAI}
                                alt="DAI Pool"
                                className="bl-0121 inline-block h-16 w-16 pp-market-glow absolute -mt-20 right-6"
                              />
                            </div>
                          </div>
                          <div
                            data-v-ac9cfe0a=""
                            className="pp-card-body space-y-4 font-bold"
                          >
                            <div data-v-ac9cfe0a="">
                              <div
                                data-v-ac9cfe0a=""
                                className="flex items-center gap-1"
                              >
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-xl text-grass"
                                  data-test="max-apy"
                                >
                                  <span
                                    title="9.673293627705881"
                                    className="whitespace-nowrap"
                                  >
                                    3.02
                                  </span>
                                  %
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  className="text-base font-normal text-white"
                                >
                                  {" "}
                                  p.a{" "}
                                </span>
                                <span
                                  data-v-ac9cfe0a=""
                                  data-v-tippy=""
                                  aria-expanded="false"
                                >
                                  <svg
                                    data-v-ac9cfe0a=""
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-emerald"
                                  >
                                    <g clip-path="url(#clip0_12081_62215)">
                                      <path
                                        d="M9.8448 0.0595203C9.93044 0.109166 9.99671 0.186387 10.0328 0.278566C10.0689 0.370745 10.0726 0.472434 10.0434 0.56702L8.46755 5.68752H11.3752C11.4606 5.68749 11.5442 5.71247 11.6156 5.7594C11.687 5.80633 11.7431 5.87314 11.777 5.95159C11.8109 6.03004 11.821 6.11669 11.8062 6.20084C11.7914 6.285 11.7523 6.36297 11.6937 6.42515L4.69367 13.8626C4.62594 13.9347 4.53581 13.9816 4.43798 13.9959C4.34014 14.0101 4.24036 13.9908 4.15491 13.9411C4.06946 13.8913 4.00337 13.8141 3.96742 13.722C3.93146 13.6299 3.92776 13.5284 3.95692 13.4339L5.5328 8.31252H2.62517C2.53973 8.31255 2.45614 8.28757 2.38473 8.24064C2.31332 8.19371 2.25722 8.1269 2.22334 8.04845C2.18947 7.97 2.17931 7.88335 2.19412 7.7992C2.20893 7.71504 2.24806 7.63707 2.30667 7.57489L9.30667 0.137395C9.37432 0.0654695 9.46431 0.0185428 9.56201 0.00424529C9.65971 -0.0100523 9.75938 0.0091202 9.8448 0.0586453V0.0595203Z"
                                        fill="currentColor"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_12081_62215">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        ></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div data-v-52d5a9f9="" className="mt-4">
                              <div
                                data-v-52d5a9f9=""
                                className="bg-water-200/10 text-water-300/75 italic py-1 px-2 rounded-[5px]"
                                style={{ fontWeight: "400" }}
                              >
                                {" "}
                                No position{" "}
                              </div>
                            </div>
                            <div data-v-ac9cfe0a="" className="flex">
                              <div className="">
                                <span data-v-ac9cfe0a="" data-v-tippy="">
                                  {" "}
                                  Maturity{" "}
                                </span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div
                                  data-v-ac9cfe0a=""
                                  className="flex flex-wrap justify-end gap-x-1"
                                >
                                  <span
                                    data-v-ac9cfe0a=""
                                    data-test="expiry"
                                    className="font-normal text-primary"
                                  >
                                    25 Sep 2025
                                  </span>
                                  <span
                                    data-v-ac9cfe0a=""
                                    title="25/09/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="font-normal"
                                  >
                                    <span className=""></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DappEarn;

import React, {useState} from "react";
import Slider from "react-slick";
import FadeIn from "../../components/fadeAnimations/FadeIn";
// import { CopyToClipboard } from "react-copy-to-clipboard";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import iconCmc from "../../assets/images/partners/Cmc.png";
import iconCoingecko from "../../assets/images/partners/coingeko.png";
import iconDextools from "../../assets/images/partners/dextools.png";
import iconDexView from "../../assets/images/partners/DexView.png";
import iconEtherscan from "../../assets/images/partners/etherScan.png";
import iconPinkSale from "../../assets/images/partners/PinkSale.png";
import iconBitget from "../../assets/images/partners/BITGET.webp";
import iconBitMart from "../../assets/images/partners/BITMART.webp";
import iconCoinsult from "../../assets/images/partners/coinsult.webp";
import iconGateIo from "../../assets/images/partners/GATE.IO.webp";
import iconKucoin from "../../assets/images/partners/KUCOIN.webp";
import iconMex from "../../assets/images/partners/MEXC.webp";

import imgEthereum from "../../assets/images/ethereum.webp";
import imgMatic from "../../assets/images/matic.png";
import imgBSC from "../../assets/images/bnb.webp";
import imgOp from "../../assets/images/optimism.png";
import ytImg from "../../assets/images/img4.png";

import iconCopy from "../../assets/images/icons/copy-svgrepo-com.svg";
import iconCopied from "../../assets/images/icons/copy-success-svgrepo-com.svg";

import iconBalance from "../../assets/images/logo/balance.png";

import backgroundVideo from "../../assets/videos/wave-bg.webm";
import balanceVideo from "../../assets/videos/out.webm";
import graphVideo from "../../assets/videos/gifgrafic.gif";

import backgroundBlack from "../../assets/images/background_dark_special1-scaled.webp";
import backgroundHomeDesktop from "../../assets/images/homebg1.webp";
import backgroundHomeMobile from "../../assets/images/new-dawn-cropped-1-e1660801361242.jpg";
import backgroundCountDesktop from "../../assets/images/background_dark5-scaled.webp";

import "./styles.css";
import "./elementor.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const [isCopied, setIsCopied] = useState(false);
  const contractNumber = "0x000000000000000000000000";
  const partnersSlickerSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
  };
  const roadmapSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
  };
  const homeSlickerSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const partners = [
    { href: "https://coinmarketcap.com/", src: iconCmc },
    { href: "https://www.coingecko.com/", src: iconCoingecko },
    { href: "https://www.dexview.com/", src: iconDexView },
    { href: "https://etherscan.io/", src: iconEtherscan },
    { href: "https://www.pinksale.finance/", src: iconPinkSale },
    { href: "https://www.bitget.com/", src: iconBitget },
    { href: "https://www.bitmart.com/en-US", src: iconBitMart },
    { href: "https://coinsult.net/", src: iconCoinsult },
    { href: "https://www.gate.io/", src: iconGateIo },
    { href: "https://www.kucoin.com/", src: iconKucoin },
    { href: "https://www.mexc.com/", src: iconMex },
  ];

  const copy = (copy) => {
    var copyText = copy
    // var copyText = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0, 99999);
    const inicio = copyText.slice(0, 4);
    const fim = copyText.slice(-4);
    navigator.clipboard.writeText(copyText);
    setIsCopied(true)
}
  return (
    <>
      <Header home />
      <section id="home" className="home-section">
        <div className="desktop-only background-desktop"></div>
        <div className="mobile-only background-mobile"></div>
        <div className="home-section-container">
          <div className="home-container">
            <>
              <div
                onClick={() => {
                  copy(contractNumber);
                }}
              >
                <span className="dc-title dc-contract dc-contract--desktop">
                  <strong>Contract: </strong><span>{contractNumber}</span>
                  {isCopied ? (
                    <img src={iconCopied} alt="copied" />
                  ) : (
                    <img src={iconCopy} alt="copy" />
                  )}
                </span>
              </div>
              <div
                onClick={() => {
                  copy(contractNumber);
                }}
              >
                <span className="dc-title dc-contract dc-contract--mobile">
                  <strong>Copy Contract</strong>
                  {isCopied ? (
                    <img src={iconCopied} alt="copied" />
                  ) : (
                    <img src={iconCopy} alt="copy" />
                  )}
                </span>
              </div>
                      <div style={{width: "100%", height: "10px"}}></div>
            </> 
            <h1>Balance Yield</h1>
            <p className="desktop-only">
              The tokenization process on Balance Network goes beyond a
              procedural step; it epitomizes a transformative mechanism, shaping
              how users engage with yields. Starting with the conversion of
              yield tokens into BN (standardized yield tokens), the subsequent
              dissection into Principal Token (PT) and Yield Token (YT)
              establishes a granular and well-defined structure for every
              tokenized yield.
            </p>
            <div className="home-slicker home-slicker-container">
              <Slider className="home-slicker" {...homeSlickerSettings}>
                <div>
                  <a
                    className="ticker border-neon-blue"
                    target="_blank"
                    href="/earn"
                  >
                    <span className="ticker--icon-spacer"></span>
                    <span className="ticker--icon-neon blue-neon"></span>
                    <img
                      className="ticker--icon"
                      src={iconBalance}
                      alt="balance"
                    />

                    <div className="ticker--text">
                      <div className="ticker--token">BALANCE</div>

                      <div className="ticker--prices">
                        <span className="ticker--discounted-percent">
                          120.16%
                        </span>
                        <span className="ticker--nested-text">fixed yield</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    className="ticker border-neon-grey"
                    target="_blank"
                    href="/earn"
                  >
                    <span className="ticker--icon-spacer"></span>
                    <span className="ticker--icon-neon grey-neon"></span>
                    <img className="ticker--icon" src={imgEthereum} alt="ETH" />

                    <div className="ticker--text">
                      <div className="ticker--token">ETH</div>

                      <div className="ticker--prices">
                        <span className="ticker--discounted-percent">
                          4.48%
                        </span>
                        <span className="ticker--nested-text">fixed yield</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    className="ticker border-neon-purple"
                    target="_blank"
                    href="/earn"
                  >
                    <span className="ticker--icon-spacer"></span>
                    <span className="ticker--icon-neon purple-neon"></span>
                    <img
                      className="ticker--icon"
                      src={imgMatic}
                      alt="PT-sDAI-26SEP2024"
                    />

                    <div className="ticker--text">
                      <div className="ticker--token">MATIC</div>

                      <div className="ticker--prices">
                        <span className="ticker--discounted-percent">
                          5.22%
                        </span>
                        <span className="ticker--nested-text">fixed yield</span>
                      </div>
                    </div>
                  </a>
                </div>
              </Slider>
            </div>

            <a
              href="https://www.pinksale.finance/launchpad/0x994172E5D6140839B80B40ECa350f3Ed90dEC336?chain=ETH"
              rel="noreferrer"
              target="_blank"
              className="balance-button"
            >
              Join Presale
            </a>
          </div>
          <div className="home-container-buttons">
            <div>
              <a
                className="ticker border-neon-blue"
                target="_blank"
                href="/earn"
              >
                <span className="ticker--icon-spacer"></span>
                <span className="ticker--icon-neon blue-neon"></span>
                <img className="ticker--icon" src={iconBalance} alt="balance" />

                <div className="ticker--text">
                  <div className="ticker--token">BALANCE</div>

                  <div className="ticker--prices">
                    <span className="ticker--discounted-percent">120.16%</span>
                    <span className="ticker--nested-text">fixed yield</span>
                  </div>
                </div>
              </a>
            </div>
            <div>
              <a
                className="ticker border-neon-grey"
                target="_blank"
                href="/earn"
              >
                <span className="ticker--icon-spacer"></span>
                <span className="ticker--icon-neon grey-neon"></span>
                <img className="ticker--icon" src={imgEthereum} alt="ETH" />

                <div className="ticker--text">
                  <div className="ticker--token">ETH</div>

                  <div className="ticker--prices">
                    <span className="ticker--discounted-percent">4.48%</span>
                    <span className="ticker--nested-text">fixed yield</span>
                  </div>
                </div>
              </a>
            </div>
            <div>
              <a
                className="ticker border-neon-purple"
                target="_blank"
                href="/earn"
              >
                <span className="ticker--icon-spacer"></span>
                <span className="ticker--icon-neon purple-neon"></span>
                <img
                  className="ticker--icon"
                  src={imgMatic}
                  alt="PT-sDAI-26SEP2024"
                />

                <div className="ticker--text">
                  <div className="ticker--token">MATIC</div>

                  <div className="ticker--prices">
                    <span className="ticker--discounted-percent">5.22%</span>
                    <span className="ticker--nested-text">fixed yield</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-0840347 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle section-2"
        data-id="0840347"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-background-overlay"></div>
        <div className="elementor-container elementor-column-gap-default">
          <FadeIn
            type="fadeleft"
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-52e7e33 animated fadeInLeft"
            data-id="52e7e33"
            data-element_type="column"
            data-settings='{"motion_fx_motion_fx_mouse":"yes","animation":"fadeInLeft"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <video
                src={balanceVideo}
                loop
                autoPlay
                muted
                className="elementor-element elementor-element-2e2248e elementor-hidden-desktop elementor-hidden-tablet elementor-align-center elementor-widget elementor-widget-lottie mobile-only"
              ></video>
              <div
                className="elementor-element elementor-element-84a531f elementor-view-default elementor-widget elementor-widget-icon"
                data-id="84a531f"
                data-element_type="widget"
                data-widget_type="icon.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-icon-wrapper">
                    <div className="elementor-icon">
                      <i aria-hidden="true" className="fas fa-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-b688823 animated-slow elementor-widget elementor-widget-heading animated fadeIn"
                data-id="b688823"
                data-element_type="widget"
                data-settings='{"_animation":"fadeIn"}'
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h1 className="elementor-heading-title elementor-size-default res-text-align-left">
                    Fixed Yield for Everyone
                  </h1>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-1cc90c5 elementor-widget elementor-widget-text-editor"
                data-id="1cc90c5"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container res-text-align-left">
                  <p>
                    Discover your steadiness amid fluctuating yields. No
                    period of confinement.
                  </p>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-f922e2a elementor-mobile-align-center elementor-align-left elementor-widget elementor-widget-button"
                data-id="f922e2a"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a href="#" target="_blank" className="balance-button">
                      <span className="">
                        <span className="">Launch Balance Earn</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </FadeIn>
          <div
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-63a7130 elementor-hidden-mobile"
            data-id="63a7130"
            data-element_type="column"
          >
            <FadeIn className="elementor-widget-wrap elementor-element-populated desktop-only">
              <video
                src={balanceVideo}
                playsinline
                loop
                autoPlay
                muted
                className="elementor-element elementor-element-b30dd45 elementor-hidden-mobile elementor-align-center elementor-widget elementor-widget-lottie"
              ></video>
            </FadeIn>
          </div>
          <FadeIn
            type="faderight"
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0a847bb animated fadeInRight"
            data-id="0a847bb"
            data-element_type="column"
            data-settings='{"motion_fx_motion_fx_mouse":"yes","animation":"fadeInRight"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-a864482 elementor-view-default elementor-widget elementor-widget-icon"
                data-id="a864482"
                data-element_type="widget"
                data-widget_type="icon.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-icon-wrapper">
                    <div className="elementor-icon">
                      <i aria-hidden="true" className="far fa-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-3b98f73 animated-slow elementor-widget elementor-widget-heading animated fadeIn"
                data-id="3b98f73"
                data-element_type="widget"
                data-settings='{"_animation":"fadeIn"}'
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h1 className="elementor-heading-title elementor-size-default res-text-align-right">
                    Control Your Yield
                  </h1>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-d822fe9 elementor-widget elementor-widget-text-editor"
                data-id="d822fe9"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container res-text-align-right">
                  <p>
                    Extend your yield or safeguard your yield exposure, the
                    decision is yours.
                  </p>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-2663698 elementor-align-right elementor-mobile-align-center elementor-widget elementor-widget-button"
                data-id="2663698"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a href="#" target="_blank" className="balance-button">
                      <span className="">
                        <span className="">Launch Balance Trade</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </FadeIn>
        </div>
      </section>

      <section id="tokenomics" className="section">
        <div className="background">
          <img src={backgroundBlack} alt="" style={{ opacity: "0.1" }} />
        </div>
        <h1>TOKENOMICS</h1>
        <div className="tokenomics-content justify-center">
          <FadeIn type="fadebottom">
            {" "}
            <div className="tokenomics">
              <h5>28.02%</h5>
              <span>Presale </span>
            </div>
          </FadeIn>
          <FadeIn type="fadebottom">
            {" "}
            <div className="tokenomics">
              <h5>11.95%</h5>
              <span>Liquidity </span>
            </div>
          </FadeIn>
          <FadeIn type="fadebottom">
            {" "}
            <div className="tokenomics">
              <h5>10.00%</h5>
              <span>Cex Listing 1 and 2</span>
            </div>
          </FadeIn>
          <FadeIn type="fadebottom">
            {" "}
            <div className="tokenomics">
              <h5>14.00%</h5>
              <span>Yield Tokenization</span>
            </div>
          </FadeIn>
          <FadeIn type="fadebottom">
            {" "}
            <div className="tokenomics">
              <h5>8.00%</h5>
              <span>Network AMM</span>
            </div>
          </FadeIn>
          <FadeIn type="fadebottom">
            {" "}
            <div className="tokenomics">
              <h5>9.00%</h5>
              <span>Ecosystem Development</span>
            </div>
          </FadeIn>
          <FadeIn type="fadebottom">
            {" "}
            <div className="tokenomics">
              <h5>9.00%</h5>
              <span>Advanced Yield Strategies</span>
            </div>
          </FadeIn>
          <FadeIn type="fadebottom">
            {" "}
            <div className="tokenomics">
              <h5>8.00%</h5>
              <span>
                Explore partnerships with decentralized exchanges (DEX)
              </span>
            </div>
          </FadeIn>
          <FadeIn type="fadebottom">
            {" "}
            <div className="tokenomics">
              <h5>2.03%</h5>
              <span>Marketing and Aidrop</span>
            </div>
          </FadeIn>
        </div>
      </section>
      <section id="roadmap" className="section">
        <div className="background">
          <img src={backgroundCountDesktop} alt="" />
        </div>
        <div className="partners-container">
          <h1>ROADMAP</h1>
          <FadeIn type="fadebottom">
            <Slider className="roadmap-slicker" {...roadmapSettings}>
              <div className="roadmap-card">
                <h2>Q1 2023</h2>
                <p>
                  Launch social media campaigns to increase platform awareness
                  and user acquisition. <br />
                  Begin content marketing efforts, including blog posts and
                  educational materials on yield strategies.
                </p>
                <p>
                  Conduct a thorough security audit of smart contracts and
                  platform infrastructure. <br />
                  Implement two-factor authentication for user accounts.
                </p>
              </div>
              <div className="roadmap-card">
                <h2>Q1 2023</h2>
                <p>
                  Collaborate with influencers and thought leaders in the DeFi
                  space for wider reach. <br /> Introduce a referral program to
                  incentivize user growth.
                </p>
                <p>
                  Enhance data encryption protocols to fortify user data
                  protection. <br /> Implement a bug bounty program to encourage
                  community involvement in identifying vulnerabilities.
                </p>
              </div>
              <div className="roadmap-card">
                <h2>Q3 2023</h2>
                <p>
                  Launch community engagement initiatives such as AMAs (Ask Me
                  Anything) and webinars. <br /> Introduce a user-friendly
                  mobile app for enhanced accessibility.
                </p>
                <p>
                  Perform regular penetration testing to identify and address
                  potential security vulnerabilities. <br /> Strengthen network
                  security through partnerships with cybersecurity firms.
                </p>
              </div>
              <div className="roadmap-card">
                <h2>Q4 2023</h2>
                <p>
                  Expand educational initiatives, including video tutorials and
                  a comprehensive knowledge base. <br /> Launch targeted
                  advertising campaigns to attract specific user segments.
                </p>
                <p>
                  Implement multi-signature wallets for an added layer of user
                  fund protection. <br /> Establish a security incident response
                  team for swift resolution of any security issues.
                </p>
              </div>
              <div className="roadmap-card">
                <h2>Q1 2025</h2>
                <p>
                  Explore partnerships with decentralized exchanges (DEX) and
                  other DeFi projects for cross-promotion. <br /> Enhance user
                  onboarding processes for a smoother experience.
                </p>
                <p>
                  Conduct regular security training sessions for the development
                  team. <br /> Integrate decentralized identity solutions for
                  user authentication.
                </p>
              </div>
              <div className="roadmap-card">
                <h2>Q2 2025</h2>
                <p>
                  Implement a community governance model to involve users in
                  decision-making processes. <br /> Launch a loyalty program to
                  reward long-term users.
                </p>
                <p>
                  Explore the implementation of privacy-focused technologies
                  such as zero-knowledge proofs. <br /> Regularly update and
                  patch software to protect against emerging security threats.
                </p>
              </div>
              <div className="roadmap-card">
                <h2>Q3 2025</h2>
                <p>
                  Host virtual events and conferences to position Balance
                  Network as an industry leader. <br /> Explore partnerships
                  with traditional financial institutions for strategic
                  collaborations.
                </p>
                <p>
                  Enhance server infrastructure to handle increasing user
                  traffic securely. <br /> Collaborate with cybersecurity
                  researchers for continuous threat intelligence.
                </p>
              </div>
              <div className="roadmap-card">
                <h2>Q4 2025</h2>
                <p>
                  Launch a decentralized autonomous organization (DAO) for
                  community-driven decision-making. <br /> Expand marketing
                  efforts to target international audiences.
                </p>
                <p>
                  Explore integration with third-party security providers for
                  continuous monitoring. <br /> Conduct regular external
                  security audits to ensure compliance with industry standards.
                </p>
              </div>
            </Slider>
          </FadeIn>
        </div>
      </section>

      <section
        id="cross-chain"
        className="chain-list-section elementor-section elementor-top-section elementor-element elementor-element-98e7638 elementor-section-height-min-height elementor-section-items-top elementor-section-content-top elementor-section-boxed elementor-section-height-default"
      >
        <div className="background">
          <img src={backgroundBlack} alt="" style={{ opacity: "0.1" }} />
        </div>
        <h1>Cross-Chain Compatible</h1>
        <div className="chain-list-wrapper justify-center">
          <div className="chain-list-item bl-0201">
            <img src={imgEthereum} />
            <div className="chain-list-item__right">
              <span className="chain-name">Ethereum</span>
              <a
                href="/market"
                target="_blank"
                className="chain-button bl-0202"
              >
                Launch App
              </a>
            </div>
          </div>
          <div className="chain-list-item bl-0203">
            <img src={imgMatic} />
            <div className="chain-list-item__right">
              <span className="chain-name">Polygon</span>
              <a
                href="/market"
                target="_blank"
                className="chain-button bl-0204"
              >
                Launch App
              </a>
            </div>
          </div>
          <div class="chain-list-item bl-0209">
            <img src={imgBSC} />
            <div class="chain-list-item__right">
              <span class="chain-name">BNB Chain</span>
              <a href="/market" target="_blank" class="chain-button 0209">
                Launch App
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="technology bl-0210 elementor-section elementor-top-section elementor-element elementor-element-6582b1c elementor-section-height-min-height elementor-section-items-top elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-motion-effects-element elementor-motion-effects-element-type-background"
        data-id="6582b1c"
        id="tecnology"
        data-element_type="section"
        data-settings='{"background_background":"classic","background_motion_fx_motion_fx_scrolling":"yes","background_motion_fx_translateY_effect":"yes","background_motion_fx_translateY_direction":"negative","background_motion_fx_translateY_speed":{"unit":"px","size":4.9000000000000003552713678800500929355621337890625,"sizes":[]},"background_motion_fx_translateY_affectedRange":{"unit":"%","size":"","sizes":{"start":0,"end":100}},"background_motion_fx_devices":["desktop","tablet","mobile"]}'
      >
        <div className="elementor-motion-effects-container">
          <div className="elementor-motion-effects-layer bl-0211"></div>
        </div>
        <div className="elementor-background-overlay"></div>
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b32b38d"
            data-id="b32b38d"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-6d9bab7 elementor-widget elementor-widget-heading animated fadeInUp"
                data-id="6d9bab7"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInUp"}'
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h1 className="elementor-heading-title elementor-size-default">
                    Core Technology
                  </h1>
                </div>
              </div>
              <section
                className="elementor-section elementor-inner-section elementor-element elementor-element-c6b7dc3 elementor-section-full_width elementor-section-height-min-height elementor-section-height-default animated fadeInDown"
                data-id="c6b7dc3"
                data-element_type="section"
                data-settings='{"animation":"fadeInDown"}'
              >
                <FadeIn
                  type="fadetop"
                  className="elementor-container elementor-column-gap-default"
                >
                  <div
                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c51f48d animated zoomIn"
                    data-id="c51f48d"
                    data-element_type="column"
                    data-settings='{"background_background":"gradient","animation":"zoomIn"}'
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div className="elementor-background-overlay"></div>
                      <div
                        className="elementor-element elementor-element-50a7b4a elementor-align-center elementor-widget elementor-widget-lottie"
                        data-id="50a7b4a"
                        data-element_type="widget"
                        data-settings='{"source_json":{"url":"\/pendle\/wp-content\/uploads\/2022\/08\/ptyt.json","id":2182,"alt":"","source":"library"},"loop":"yes","reverse_animation":"yes","lazyload":"yes","source":"media_file","caption_source":"none","link_to":"none","trigger":"arriving_to_viewport","viewport":{"unit":"%","size":"","sizes":{"start":0,"end":100}},"play_speed":{"unit":"px","size":1,"sizes":[]},"start_point":{"unit":"%","size":"0","sizes":[]},"end_point":{"unit":"%","size":"100","sizes":[]},"renderer":"svg"}'
                        data-widget_type="lottie.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="e-lottie__container">
                            <div className="e-lottie__animation"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-0efda73 animated-slow elementor-widget elementor-widget-heading animated fadeIn"
                        data-id="0efda73"
                        data-element_type="widget"
                        data-settings='{"_animation":"fadeIn"}'
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h3 className="elementor-heading-title elementor-size-default">
                            Yield Tokenisation
                          </h3>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-a17f17f elementor-widget elementor-widget-text-editor"
                        data-id="a17f17f"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          Split any yield-bearing asset into distinct yield and
                          principal components for maximum control.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1f6bf76 animated zoomIn"
                    data-id="1f6bf76"
                    data-element_type="column"
                    data-settings='{"background_background":"classic","animation":"zoomIn"}'
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div className="elementor-background-overlay"></div>
                      <div
                        className="elementor-element elementor-element-c28fdef elementor-aspect-ratio-32 elementor-widget elementor-widget-video"
                        data-id="c28fdef"
                        data-element_type="widget"
                        data-settings='{"video_type":"hosted","autoplay":"yes","play_on_mobile":"yes","mute":"yes","loop":"yes","aspect_ratio":"32"}'
                        data-widget_type="video.default"
                      >
                        <div className="elementor-widget-container">
                          <img
                            src={graphVideo}
                            alt=""
                            className="gif-technology"
                          />
                          {/* <div className="e-hosted-video elementor-wrapper elementor-fit-aspect-ratio elementor-open-inline">
                            <video
                              className="elementor-video"
                              src={graphVideo}
                              autoPlay
                              loop
                              muted
                              playsinline=""
                              controlslist="nodownload"
                            ></video>
                          </div> */}
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-46589b3 animated-slow elementor-widget elementor-widget-heading animated fadeIn"
                        data-id="46589b3"
                        data-element_type="widget"
                        data-settings='{"_animation":"fadeIn"}'
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          {/* <h3 className="elementor-heading-title elementor-size-default">
                            Balance AMM
                          </h3> */}
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-e5c644c elementor-widget elementor-widget-text-editor"
                        data-id="e5c644c"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <p>
                            Created for yield trading with focused liquidity, a
                            dual fee system, and minimal worries about
                            impermanent loss.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-0e0d195 animated zoomIn"
                    data-id="0e0d195"
                    data-element_type="column"
                    data-settings='{"background_background":"classic","animation":"zoomIn"}'
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div className="elementor-background-overlay"></div>
                      <div
                        className="elementor-element elementor-element-b4b340b elementor-align-center elementor-widget elementor-widget-lottie"
                        data-id="b4b340b"
                        data-element_type="widget"
                        data-settings='{"source_json":{"url":"\/pendle\/wp-content\/uploads\/2022\/07\/vependle2.json","id":1664,"alt":"","source":"library"},"loop":"yes","lazyload":"yes","source":"media_file","caption_source":"none","link_to":"none","trigger":"arriving_to_viewport","viewport":{"unit":"%","size":"","sizes":{"start":0,"end":100}},"play_speed":{"unit":"px","size":1,"sizes":[]},"start_point":{"unit":"%","size":"0","sizes":[]},"end_point":{"unit":"%","size":"100","sizes":[]},"renderer":"svg"}'
                        data-widget_type="lottie.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="e-lottie__container">
                            <div className="e-lottie__animation"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-c95576e animated-slow elementor-widget elementor-widget-heading animated fadeIn"
                        data-id="c95576e"
                        data-element_type="widget"
                        data-settings='{"_animation":"fadeIn"}'
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h3 className="elementor-heading-title elementor-size-default">
                            BALANCE
                          </h3>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-09a29ae elementor-widget elementor-widget-text-editor"
                        data-id="09a29ae"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <p>
                            Lock $Balance to secure a position in the protocol.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </FadeIn>
              </section>
              <div
                className="elementor-element elementor-element-8b44e29 elementor-align-center elementor-widget elementor-widget-button"
                data-id="8b44e29"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      href="https://meteor-swap-1.gitbook.io/meteor-swap"
                      target="_blank"
                      className="balance-button"
                    >
                      <span className="">
                        <span className="">Balance Docs</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="ytokenization" className="section">
        <div className="background">
          <img src={backgroundBlack} alt="" />
        </div>
        <div className="partners-container">
          {/* <h1>YIELD TOKENIZATION</h1> */}
          <div className="ytokenization-container">
            <FadeIn type="fadeleft">
              <img src={ytImg} alt="" />
            </FadeIn>
            <FadeIn type="faderight">
              <div>
                <h4>Precision Unleashed</h4>
                <p>
                  The tokenization process on Balance Network goes beyond a
                  procedural step; it epitomizes a transformative mechanism,
                  shaping how users engage with yields. Starting with the
                  conversion of yield tokens into BN (standardized yield
                  tokens), the subsequent dissection into Principal Token (PT)
                  and Yield Token (YT) establishes a granular and well-defined
                  structure for every tokenized yield.
                </p>
              </div>
            </FadeIn>
          </div>
          {/* <div className="audits-display">
            <div className="audits-card">
              <div
                className="d-flex align-center justify-center"
                style={{ padding: "0px 0px 15px 0px" }}
              >
                <img
                  width="80"
                  height="80"
                  src={iconBalance}
                  alt=""
                  style={{ borderRadius: "50%", border: "2px solid white" }}
                />
                <div
                  className="d-flex f-column"
                  style={{ padding: "0px 0px 15px 15px" }}
                >
                  <h4 className="text-primary">Lorem</h4>
                  <p className="text-secondary">Lorem ipsum, dolor sit</p>
                </div>
              </div>
              <button className="balance-button">Audit Report</button>
            </div>
            <div className="audits-card">
              <div
                className="d-flex align-center justify-center"
                style={{ padding: "0px 0px 15px 0px" }}
              >
                <img
                  width="80"
                  height="80"
                  src={iconBalance}
                  alt=""
                  style={{ borderRadius: "50%", border: "2px solid white" }}
                />
                <div
                  className="d-flex f-column"
                  style={{ padding: "0px 0px 15px 15px" }}
                >
                  <h4 className="text-primary">Lorem</h4>
                  <p className="text-secondary">Lorem ipsum, dolor sit</p>
                </div>
              </div>
              <button className="balance-button">Audit Report</button>
            </div>
            <div className="audits-card">
              <div
                className="d-flex align-center justify-center"
                style={{ padding: "0px 0px 15px 0px" }}
              >
                <img
                  width="80"
                  height="80"
                  src={iconBalance}
                  alt=""
                  style={{ borderRadius: "50%", border: "2px solid white" }}
                />
                <div
                  className="d-flex f-column"
                  style={{ padding: "0px 0px 15px 15px" }}
                >
                  <h4 className="text-primary">Lorem</h4>
                  <p className="text-secondary">Lorem ipsum, dolor sit</p>
                </div>
              </div>
              <button className="balance-button">Audit Report</button>
            </div>
            <div className="audits-card">
              <div
                className="d-flex align-center justify-center"
                style={{ padding: "0px 0px 15px 0px" }}
              >
                <img
                  width="80"
                  height="80"
                  src={iconBalance}
                  alt=""
                  style={{ borderRadius: "50%", border: "2px solid white" }}
                />
                <div
                  className="d-flex f-column"
                  style={{ padding: "0px 0px 15px 15px" }}
                >
                  <h4 className="text-primary">Lorem</h4>
                  <p className="text-secondary">Lorem ipsum, dolor sit</p>
                </div>
              </div>
              <button className="balance-button">Audit Report</button>
            </div>
            <div className="audits-card">
              <div
                className="d-flex align-center justify-center"
                style={{ padding: "0px 0px 15px 0px" }}
              >
                <img
                  width="80"
                  height="80"
                  src={iconBalance}
                  alt=""
                  style={{ borderRadius: "50%", border: "2px solid white" }}
                />
                <div
                  className="d-flex f-column"
                  style={{ padding: "0px 0px 15px 15px" }}
                >
                  <h4 className="text-primary">Lorem</h4>
                  <p className="text-secondary">Lorem ipsum, dolor sit</p>
                </div>
              </div>
              <button className="balance-button">Audit Report</button>
            </div>
            <div className="audits-card">
              <div
                className="d-flex align-center justify-center"
                style={{ padding: "0px 0px 15px 0px" }}
              >
                <img
                  width="80"
                  height="80"
                  src={iconBalance}
                  alt=""
                  style={{ borderRadius: "50%", border: "2px solid white" }}
                />
                <div
                  className="d-flex f-column"
                  style={{ padding: "0px 0px 15px 15px" }}
                >
                  <h4 className="text-primary">Lorem</h4>
                  <p className="text-secondary">Lorem ipsum, dolor sit</p>
                </div>
              </div>
              <button className="balance-button">Audit Report</button>
            </div>
          </div> */}
        </div>
      </section>
      <section id="partners" className="section">
        <div className="background-video">
          <video autoPlay muted loop>
            <source src={backgroundVideo} type="video/mp4" />
          </video>
        </div>
        <div className="partners-container">
          <h1>PARTNERS</h1>
          <FadeIn type="fadebottom">
            <Slider className="partners-slicker" {...partnersSlickerSettings}>
              {partners.map((partner, index) => (
                <div className="partners-card" key={`${index}__partners`}>
                  <a href={partner.href} target="_blank">
                    <img src={partner.src} alt="partner" />
                  </a>
                </div>
              ))}
            </Slider>
          </FadeIn>
        </div>
      </section>

      <Footer home />
    </>
  );
};

export default Home;

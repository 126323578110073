import React, { useState } from "react";
import logoGray from "../../assets/images/logo/grey-with-name.png";
import iconMenu from "../../assets/images/icons/menu-svgrepo-com.svg";
import iconClose from "../../assets/images/icons/close-bold-svgrepo-com.svg";
import "./styles.css";

const Header = ({
  home,
  isConnected,
  displayAddress,
  getSigner,
  provider,
  chains,
  setChainSelected,
  chainSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [chainDropDown, setChainDropDown] = useState(false);

  const handleClickChainDropDown = () => {
    setChainDropDown(!chainDropDown);
  };
  const handleClickChain = (newchain) => {
    if (newchain) {
      setChainSelected(newchain);
    }
    setChainDropDown(false);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="site-header">
      <div className="background-overlay"></div>
      {home ? (
        <div className="header-container">
          <div className="header-logo-container">
            <a href="/">
              <img
                width="880"
                height="180"
                src={logoGray}
                className="logo"
                alt=""
                loading="lazy"
              />
            </a>
          </div>
          <div className="header-nav">
            <nav className="nav-menu--main nav-menu__container nav-menu--layout-horizontal e--pointer-text e--animation-none desktop-only">
              <ul id="menu-1-faf43cc" className="nav-menu">
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-490">
                  <a href="#" className="item nav-item">
                    Home
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-490">
                  <a href="#roadmap" className="item nav-item">
                    Roadmap
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-491">
                  <a
                    href="https://meteor-swap-1.gitbook.io/meteor-swap"
                    target="_blanc"
                    className="item nav-item"
                  >
                    Whitepaper
                  </a>
                </li>
              </ul>
            </nav>

            <nav
              className={
                isOpen
                  ? "nav-menu--dropdown mobile-only isOpen"
                  : "nav-menu--dropdown mobile-only"
              }
            >
              <ul className="nav-menu-mobile">
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-490">
                  <a
                    onClick={handleClick}
                    href="#"
                    className="item nav-item"
                    tabindex="-1"
                  >
                    Home
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-490">
                  <a
                    onClick={handleClick}
                    href="#roadmap"
                    className="item nav-item"
                    tabindex="-1"
                  >
                    Roadmap
                  </a>
                </li>
                <li
                  onClick={handleClick}
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-491"
                >
                  <a
                    href="https://meteor-swap-1.gitbook.io/meteor-swap"
                    target="_blanc"
                    className="item nav-item"
                    tabindex="-1"
                  >
                    Whitepaper
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-491">
                  <a href="/market">
                    <button type="button" className="balance-button">
                      DAPP
                    </button>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="right-side">
            <div
              className="menu-toggle mobile-only"
              role="button"
              onClick={handleClick}
            >
              <img
                className={isOpen ? "isvisible" : "ishiden"}
                src={iconClose}
                alt=""
              />
              <img
                className={isOpen ? "ishiden" : "isvisible"}
                src={iconMenu}
                alt=""
              />
            </div>
            <div className="desktop-only">
              <div className="header-btns">
              <a href="/market">
                <button type="button" className="balance-button">
                  DAPP
                </button>
              </a>
            </div>
            </div>
            
          </div>
        </div>
      ) : (
        <div className="header-container">
          <div className="header-logo-container">
            <a href="/">
              <img
                width="880"
                height="180"
                src={logoGray}
                className="logo"
                alt=""
                loading="lazy"
              />
            </a>
          </div>
          <div className="right-side">
            <div className="header-nav">
              <nav className="nav-menu--main nav-menu__container nav-menu--layout-horizontal e--pointer-text e--animation-none desktop-only">
                <ul id="menu-1-faf43cc" className="nav-menu">
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-491">
                    <a href="/market" className="item nav-item">
                      Market
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-491">
                    <a href="/earn" className="item nav-item">
                      Earn
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-490">
                    <a href="/swap" className="item nav-item">
                      Swap
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-490">
                    <a href="/bridge" className="item nav-item">
                      Bridge
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="chain-btn mobile-only" style={{padding: "2px", marginRight: "20px"}}>
                  <button
                    onClick={() => handleClickChainDropDown()}
                    onBlur={() => setChainDropDown(false)}
                  >
                    <img src={chainSelected.src} alt={chainSelected.name} />
                  </button>
                  <div
                    className={
                      chainDropDown
                        ? "chain-dropdown chain-dropdown__show"
                        : "chain-dropdown"
                    }
                  >
                    <div>
                    <ul>
                      {chains.map((chain, index) => (
                        <li
                          key={`chain__${index}`}
                          onClick={() => handleClickChain(chain)}
                        >
                          <img src={chain.src} alt={chain.name} />
                          <span>{chain.name}</span>
                        </li>
                      ))}
                    </ul>  
                    </div>
                    
                  </div>
                </div>
              <div
                className="menu-toggle mobile-only"
                role="button"
                onClick={handleClick}
              >
                <img
                  className={isOpen ? "isvisible" : "ishiden"}
                  src={iconClose}
                  alt=""
                />
                <img
                  className={isOpen ? "ishiden" : "isvisible"}
                  src={iconMenu}
                  alt=""
                />
              </div>
              <nav
                className={
                  isOpen
                    ? "nav-menu--dropdown mobile-only isOpen"
                    : "nav-menu--dropdown mobile-only"
                }
              >
                <ul className="nav-menu-mobile">
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-491">
                    <a
                      onClick={handleClick}
                      href="/market"
                      className="item nav-item"
                      tabindex="-1"
                    >
                      Market
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-491">
                    <a
                      onClick={handleClick}
                      href="/earn"
                      className="item nav-item"
                      tabindex="-1"
                    >
                      Earn
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-490">
                    <a
                      onClick={handleClick}
                      href="/swap"
                      className="item nav-item"
                      tabindex="-1"
                    >
                      Swap
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-490">
                    <a
                      onClick={handleClick}
                      href="/bridge"
                      className="item nav-item"
                      tabindex="-1"
                    >
                      Bridge
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-491">
                    {isConnected() ? (
                      <button
                        type="button"
                        className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1"
                      >
                        {displayAddress || "Connect Wallet"}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1"
                        onClick={() => getSigner(provider)}
                      >
                        Connect Wallet
                      </button>
                    )}
                  </li>
                </ul>
              </nav>
            </div>

            <div className="desktop-only">
              <div className="header-btns ">
                <div className="chain-btn">
                  <button
                    onClick={() => handleClickChainDropDown()}
                    onBlur={() => setChainDropDown(false)}
                  >
                    <img src={chainSelected.src} alt={chainSelected.name} />
                  </button>
                  <div
                    className={
                      chainDropDown
                        ? "chain-dropdown chain-dropdown__show"
                        : "chain-dropdown"
                    }
                  >
                    <div>
                      <ul>
                      {chains.map((chain, index) => (
                        <li
                          key={`chain__${index}`}
                          onClick={() => handleClickChain(chain)}
                        >
                          <img src={chain.src} alt={chain.name} />
                          <span>{chain.name}</span>
                        </li>
                      ))}
                    </ul>
                    </div>
                    
                  </div>
                </div>
                {isConnected() ? (
                  <button
                    type="button"
                    className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1"
                  >
                    {displayAddress || "Connect Wallet"}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1"
                    onClick={() => getSigner(provider)}
                  >
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
